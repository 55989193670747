import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import BSAHeader from '../../components/BestSchoolsAwards/Header/Header'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'
import SponsorBanner from '../../components/BestSchoolsAwards/SponsorBanner/SponsorBanner'
import { Link } from 'gatsby'
import { SchoolAwardsContext, SchoolAwardsStates } from '../../lib/utils'

const BestSchoolsAwards = () => {
  const SchoolContext = useContext(SchoolAwardsContext)
  const titleText =
    SchoolContext === SchoolAwardsStates.FINALISTS
      ? 'Meet the Finalists'
      : SchoolContext === SchoolAwardsStates.WINNERS
      ? 'Meet the Winners'
      : 'Welcome'
  return (
    <>
      <Helmet>
        <title>
          Muddy Stilettos&apos; Best Schools Awards | Muddy Stilettos
        </title>
      </Helmet>
      <BSAHeader />
      <SponsorBanner />
      <BSAContent>
        <h1>{titleText}</h1>

        {SchoolContext === SchoolAwardsStates.OPEN ? (
          <>
            <p>
              <strong>DEADLINE EXTENSION</strong> - 3pm, 23 APRIL 2024.
            </p>
            <p>
              We’re thrilled to announce that the Muddy Stilettos Best Schools
              Awards 2024 is <strong>now open</strong>, offering a uniquely
              modern, holistic perspective on what it takes to be a brilliant
              independent school in the UK.
            </p>
            <p>
              There are 13 categories to enter, including brand new awards for
              Most Inclusive School, Best Learning Support and a Prepared for
              Life Award <strong>PLUS</strong> perennially popular categories
              such as Outstanding Pastoral Care and Brilliant Boarding School.
            </p>
            <p>
              <a href="/best-schools-awards/the-categories">
                Find out what the judges are looking for
              </a>{' '}
              and visit our{' '}
              <a href="/best-schools-awards/terms-conditions">Terms of Entry</a>
              , and <a href="/best-schools-awards/faqs">FAQs</a> for more
              information.
            </p>
            <p>
              The entry deadline has now{' '}
              <span style={{ color: 'rgb(231, 44, 94)' }}>been extended</span>{' '}
              meaning that entries close at <strong>3pm, 23 April 2024</strong>.
            </p>
            <p style={{ textAlign: 'center' }}>
              <Link
                className={ContentStyles.RegisterInterestButton}
                to="/best-schools-awards/dashboard/enter"
              >
                Get Started
              </Link>
            </p>
          </>
        ) : (
          <>
            {SchoolContext === SchoolAwardsStates.WINNERS ? (
              <>
                <p>
                  We’re thrilled to announce the Muddy Stilettos 2024 Best
                  Schools Awards Winners, in association with leading education
                  experts{' '}
                  <a
                    href="https://education.debretts.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Debrett’s Education
                  </a>
                  .{' '}
                </p>
                <p>
                  Now in their third year, our Awards have quickly grown to
                  become the UK’s most coveted independent school accolade.
                </p>
                <p>
                  We’ve been blown away by the quality of entries, with hundreds
                  of schools taking part across 13 categories, each showcasing
                  just what it takes to be the best in modern, holistic
                  education.{' '}
                </p>
                <p>
                  Go to the{' '}
                  <Link to={`/best-schools-awards/results`}>Results</Link> page
                  to find out who’s TOP OF THE CLASS, plus the schools that made
                  it to the FINALS and have been HIGHLY COMMENDED.
                </p>
                <p>Gold stars all round!</p>

                <p style={{ textAlign: 'center' }}>
                  <Link
                    className={ContentStyles.RegisterInterestButton}
                    to="/best-schools-awards/results"
                  >
                    Meet the Winners
                  </Link>
                </p>
              </>
            ) : (
              <>
                {SchoolContext === SchoolAwardsStates.FINALISTS ? (
                  <>
                    <p>
                      We are thrilled to announce Muddy’s 2024 Best Schools
                      Awards Finalists.
                    </p>
                    <p>
                      We’ve been blown away by the quality of entries - many
                      100s of schools taking part across our 13 categories. Our
                      expert panel has narrowed it down to up to five FINALISTS
                      per category.
                    </p>

                    <p>
                      Go to the{' '}
                      <Link to="/best-schools-awards/results">Results</Link>{' '}
                      page to find out who has made it into the Finals and have
                      been HIGHLY COMMENDED by the judges.{' '}
                    </p>

                    <p>
                      <strong>
                        The Winners will be announced on 27 June 2024.
                      </strong>
                    </p>

                    <p style={{ textAlign: 'center' }}>
                      <Link
                        className={ContentStyles.RegisterInterestButton}
                        to="/best-schools-awards/results"
                      >
                        View Finalists
                      </Link>
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      <strong>THE SCHOOL AWARDS 2024 ARE NOW CLOSED</strong>
                    </p>
                    <p>
                      5 Highly Commended and up to 5 Finalist Schools per
                      category will be announced on 20th June and these schools
                      will enter a second round of judging. Watch this space!
                    </p>

                    <p>
                      <a href="/best-schools-awards/the-categories">
                        Find out what the judges are looking for
                      </a>{' '}
                      and visit our{' '}
                      <a href="/best-schools-awards/terms-conditions">
                        Terms of Entry
                      </a>
                      , and <a href="/best-schools-awards/faqs">FAQs</a> for
                      more information.
                    </p>

                    <p>
                      Click <Link to="/">here</Link> to return to the main Muddy
                      Stilettos homepage.
                    </p>
                  </>
                )}
              </>
            )}
          </>
        )}
      </BSAContent>
      <Categories partialListing />
      <WhyGetInvolved />
      <TestimonialSlider />
      <BSAContent>
        <div style={{ textAlign: 'center' }}>
          <h1>Our partners</h1>
          <p>
            To find out how to get involved as a partner, contact Jamie Page
            Weeden at{' '}
            <a href="mailto:jamie@muddystilettos.co.uk">
              jamie@muddystilettos.co.uk
            </a>
            .
          </p>
          <a
            className={ContentStyles.RegisterInterestButton}
            href={`http://eepurl.com/hPrzrL`}
            target="_blank"
            rel="noreferrer"
          >
            Sign Up To Newsletters
          </a>
        </div>
      </BSAContent>
      <BSAFooter />
    </>
  )
}

export default BestSchoolsAwards
