import React, { useEffect, useRef, useState } from 'react'
import * as HeaderStyles from './Header.module.scss'
import Nav from '../Nav/Nav'
import GoHome from '../GoHome/GoHome'

const BSAHeader = () => {
  const [videoSrc, setVideoSrc] = useState<string>()
  const [imgSrc, setImgSrc] = useState<string>()
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setVideoSrc('/best-schools-awards/best-schools-awards-mobile.mp4')
          setImgSrc(
            '/best-schools-awards/best-schools-awards-video-still-mobile.jpg'
          )
        } else {
          setVideoSrc('/best-schools-awards/best-schools-awards-desktop.mp4')
          setImgSrc(
            '/best-schools-awards/best-schools-awards-video-still-desktop.jpg'
          )
        }
      }
      handleResize()

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [videoSrc])

  return (
    <div className={HeaderStyles.Header}>
      <GoHome />
      <div className={HeaderStyles.NavWrapper}>
        <Nav theme="light" site="school" />
      </div>
      <div className={HeaderStyles.Hero}>
        {videoSrc && (
          <video ref={videoRef} autoPlay muted playsInline poster={imgSrc} loop>
            <source src={videoSrc} type="video/mp4" />
            <img
              src={imgSrc}
              title="Your browser does not support the <video> tag"
            />
          </video>
        )}
      </div>
    </div>
  )
}

export default BSAHeader
