import React, { useEffect, useState } from 'react'
import * as TestimonialStyles from './TestimonialSlider.module.scss'

const slides = [
  {
    image: '/best-schools-awards/schools/magdalen-college-school.png',
    quote:
      '"MCS were delighted to receive recognition from Muddy Stilettos of our extensive partnerships and community engagement programme. The Best School Award raised the visibility of the causes we care passionately about."',
    name: 'Magdalen College School'
  },
  {
    image: '/best-schools-awards/schools/st-olaves.jpg',
    quote:
      '"Winning at the Best Experiential Learning award at the Muddy Awards this year has been a true game changer. It\'s not just a recognition of our green thumbs, but a celebration of the community spirit that makes our allotment a thriving, vibrant place. We\'re honoured and inspired to keep growing together!"',
    name: "St Olave's Prep School"
  }
  // {
  //   image:
  //     'https://dev.muddystilettos.co.uk/best-schools-awards/assets/images/testimonials/d-overbroecks.png',
  //   quote:
  //     "They take a genuine interest in the school and what makes us different. The reviews are very honest and humorous in the 'Muddy style'",
  //   name: 'd’Overbroeck’s, Oxford'
  // }
]

const TestimonialSlider = () => {
  const [current, setCurrent] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(current === slides.length ? 1 : current + 1)
    }, 5000)
    return () => clearInterval(interval)
  }, [current])

  return (
    <div className={TestimonialStyles.Testimonials}>
      <div className={TestimonialStyles.Wrapper}>
        <div
          className={TestimonialStyles.Slider}
          style={{
            left: `-${(current - 1) * 100}vw`
          }}
        >
          {slides.map((slide, index) => (
            <div key={index} className={TestimonialStyles.Slide}>
              <div className={TestimonialStyles.SlideWrap}>
                <div
                  className={TestimonialStyles.Image}
                  style={{
                    backgroundImage: `url(${slide.image})`
                  }}
                />
                <div className={TestimonialStyles.Quote}>
                  <p>{slide.quote}</p>
                  <h3>{slide.name}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TestimonialSlider
