import { StaticImage } from 'gatsby-plugin-image'
import * as SponsorBannerStyles from './SponsorBanner.module.scss'
import React from 'react'

const SponsorBanner = () => {
  return (
    <div className={SponsorBannerStyles.SponsorBanner}>
      <p>In association with</p>
      <StaticImage
        src={`../../../../static/best-schools-awards/debretts-education-gold.png`}
        alt="Best Schools Awards in Association With Debrett's Education"
        width={150}
        height={105}
      />
    </div>
  )
}

export default SponsorBanner
